// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-tips-template-js": () => import("./../src/templates/tipsTemplate.js" /* webpackChunkName: "component---src-templates-tips-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marcas-js": () => import("./../src/pages/marcas.js" /* webpackChunkName: "component---src-pages-marcas-js" */),
  "component---src-pages-nosotros-js": () => import("./../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-registrate-js": () => import("./../src/pages/registrate.js" /* webpackChunkName: "component---src-pages-registrate-js" */),
  "component---src-pages-sended-email-js": () => import("./../src/pages/sended-email.js" /* webpackChunkName: "component---src-pages-sended-email-js" */),
  "component---src-pages-tips-js": () => import("./../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */)
}

